<template>
  <div>
    <!-- mapa -->
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5" :min-height="computedHeight">
          <v-row dense>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("multistop_show_quote_title") }}</h2>
                <v-tooltip
                  top
                  content-class="bs-tooltip-top arrow"
                  :max-width="300"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text rounded-0"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span> {{ $t("multistop_show_quote_title_tooltip") }} </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <GoogleMapShow
                v-if="loaded"
                :origin="map.stops[0]"
                :stops="map.stops"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-4">
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>
                  {{ $t("multistop_list") }}
                </h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text rounded-0"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("multistop_list_tooltip") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <DataTable
                :headers="table.headers"
                :loading="table.tableLoading"
                :items="table.items"
                :showPage="true"
              />
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>
                  {{ $t("multistop_total_resume") }}
                </h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text rounded-0"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("multistop_total_resume_tooltip") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col v-if="!table.tableLoading" cols="12">
              <DataTableTotal
                :headers="tableTotal.headers"
                :items="computedTableTotal"
                :showPage="true"
              />
            </v-col>
            <v-col
              cols="12"
              v-else
              class="d-flex flex-row justify-center align-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <br />
          <v-row dense class="d-flex flex-row justify-center">
            <v-col cols="12" md="8">
              <v-btn
                color="success"
                block
                @click="handleApproveMultiStop(quoteModal)"
              >
                {{ $t("multistop_approve") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import {
  mdiInformationOutline,
  mdiArrowLeft,
  mdiRefresh,
  mdiAccount,
  mdiBagSuitcase,
  mdiWhatsapp,
} from "@mdi/js";
import GoogleMapShow from "./parts/GoogleMapShow.vue";
import DataTable from "./parts/DataTable.vue";
import DataTableTotal from "./parts/DataTableTotal.vue";
import ModalApproveMultiStop from "@/views/Multistop/parts/ModalApproveMultiStop.vue";


export default {
  components: {
    GoogleMapShow,
    DataTable,
    DataTableTotal,
  },
  data() {
    return {
      data: null,
      icons: {
        mdiInformationOutline,
        mdiArrowLeft,
        mdiRefresh,
        mdiAccount,
        mdiBagSuitcase,
        mdiWhatsapp,
      },
      map: {
        origin: {},
        destination: {},
        stops: [],
      },
      loaded: false,
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      table: {
        tableLoading: false,
        headers: [
          { text: "PARADA", value: "stopNumber", align: "center" },
          { text: "DIRECCIÓN DE ORIGEN", value: "stopOrigin", align: "start" },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "stopDestination",
            align: "start",
          },
          {
            text: "TIEMPO ESTIMADO",
            value: "stopTime",
            align: "end",
          },
          {
            text: "DISTANCIA ESTIMADA",
            value: "stopDistance",
            align: "end",
          },
          {
            text: "COSTO PARADA",
            value: "costStop",
            align: "end",
          },
          {
            text: "RECARGO",
            value: "costFee",
            align: "end",
          },
          {
            text: "TOTAL PARADA",
            value: "costTotal",
            align: "end",
          },
        ],
        items: [],
      },
      tableTotal: {
        headers: [
          { text: "CANTIDAD PARADAS", value: "stops", align: "center" },
          {
            text: "TIEMPO ESTIMADO",
            value: "stopTime",
            align: "center",
          },
          {
            text: "DISTANCIA ESTIMADA",
            value: "stopDistance",
            align: "center",
          },
          {
            text: "COSTO MULTIPARADA",
            value: "costStop",
            align: "center",
          },
          { text: "FECHA Y HORA", value: "date", align: "start", width: 180 },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
          },
        ],
      },
      quote: null,
      quoteModal: null
    };
  },
  methods: {
    ...mapActions(["setLoading"]),

    async getMultiStopQuoteShow() {
      this.setLoading(true);
      this.table.tableLoading = true;
      if (this.$route.params.id) {
        try {
          const { data } = await axios.get(
            `${process.env.VUE_APP_API_URL}/quotes/${this.$route.params.id}`
          );

          if (!!data.success) {
            this.loaded = true;
            const { quote } = data;
            this.quote = quote;
            this.quoteModal = quote;
            let length = this.quote.routes.length
            this.quoteModal['source_address'] = this.quoteModal.routes[0].source_address;
            this.quoteModal['destination_address'] = this.quoteModal.routes[length - 1].destination_address;
            const { routes } = quote;
            //origen
            this.map.stops.push({
              lat: routes[0].sourceLocation[0],
              lng: routes[0].sourceLocation[1],
            });
            //paradas
            routes.forEach((route) => {
              this.map.stops.push({
                lat: route.destinationLocation[0],
                lng: route.destinationLocation[1],
              });
            });
            //table items
            this.table.items = new Array(routes.length);
            routes.forEach((stop, index) => {
              this.table.items[index] = {
                stopNumber: index + 1,
                stopOrigin: stop.source_address,
                stopDestination: stop.destination_address,
                stopTime: Math.ceil(stop.time) + "m",
                stopTimeOnly: Math.ceil(stop.time),
                stopDistance: stop.distance + "Km",
                stopDistanceOnly: stop.distance * 1,
                costStop: this.$options.filters.currency(stop.base_estimated),
                costStopOnly: stop.base_estimated,
                costFee: this.$options.filters.currency(stop.multi_stops_fee),
                costTotal: this.$options.filters.currency(stop.estimated_fare),
                costTotalOnly: stop.estimated_fare,
              };
            });
          } else {
            throw new Error("Error in /quotes/id");
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        }
      }
      this.table.tableLoading = false;
      this.setLoading(false);
    },
    async handleApproveMultiStop(item) {
      const params = {
        fullscreen: !this.$vuetify.breakpoint.mdAndUp,
        transition: "dialog-top-transition",
        item: item,
        persistent: false,
        width: "70%",
        hideCancel: true,
        // persistent: true,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        ModalApproveMultiStop,
        params
      );
      if (dialogInstance) {
        this.$router.push({ name: "runningTrips" });
      }
    },
  },
  computed: {
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.formOpen ? "800px" : "550px";
      } else {
        return "400px";
      }
    },
    computedTableTotal() {
      const { serviceType } = this.quote;
      return [
        {
          stops: this.table.items.length,
          stopTime:
            this.table.items.reduce((accumulator, object) => {
              return accumulator + object.stopTimeOnly;
            }, 0) + "m",
          stopDistance:
            parseFloat(
              this.table.items.reduce((accumulator, object) => {
                return accumulator + object.stopDistanceOnly;
              }, 0)
            ).toFixed(2) + "Km",
          costStop: this.$options.filters.currency(
            this.table.items.reduce((accumulator, object) => {
              return accumulator + object.costTotalOnly;
            }, 0)
          ),
          date: this.quote.created_at,
          serviceType: {
            image: serviceType.type_image_url,
            type: serviceType.typename,
            capacity: serviceType.max_space,
            baggage: serviceType.baggages,
          },
        },
      ];
    },
  },
  created() {
    this.getMultiStopQuoteShow();
  },
};
</script>

<style></style>
